
import Vue_ from "vue"
import { mapActions, mapGetters } from "vuex"
import { MetaInfo } from "vue-meta"
import { VueConstructor } from "vue/types/umd"
import { replaceUrlS3 } from "~/utils/imageUrlS3"
import { SET_BREADCRUMBS } from "~/store/pages/actions"
import { SET_FW_TOKEN, SET_USER } from "~/store/auth/actions"
import { AuthenticationService } from "~/services/public/Authentication"
import { TrackingService } from "~/services/public/Tracking"
import { PhoneNumberService } from "~/services/public/PhoneNumber"
import { GET_FW_TOKEN } from "~/store/auth/getters"
import { CreditCodeService } from "~/services/public/CreditCode"
import { SET_CREDIT_CODE_VALUE } from "~/store/credit-code/actions"
import { SET_PHONE_NUMBER_VALUE } from "~/store/phone-number/actions"
import { SET_LOCATION_INFO, SET_MAP_LOCATIONS } from "~/store/location/actions"
import { SET_INTERNAL_REVIEWS } from "~/store/internal-reviews/mutations"
import { buildStructuredData } from "~/scripts/google-structured-data"

interface ComponentData {
  layout: string
  internalReviewsFilter: Record<string, any>
}

interface PageLayoutProvider {
  internalReviewsFilter: Record<string, any>
  layout: string
  setBreadcrumbs: (payload: { breadcrumbs: any }) => void
  replaceUrlS3: (url: string, config: any) => string
  setMapLocations: (location: any) => void
  setLocationInfo: (location: any) => void
  setInternalReviews: (reviews: any) => void
  setFwToken: (token: String) => void
  setUser: (user: any) => void
  setPhoneNumberValue: (phoneNumber: any) => void
  setCreditCodeValue: (creditCode: number) => void
  getLayoutFromTemplate: () => string
  authenticateAndUpdatePhone: () => Promise<void>
  updatePhoneNumber: () => Promise<void>
  useCreditCode: () => Promise<void>
  sendTrackingEvents: () => Promise<void>
}

// This hack is necessary because of the way Vue.extend works with provide/inject
// A potential solution would be to use defineComponents in Vue 3 or remove provide()
const Vue = Vue_ as VueConstructor<Vue_ & PageLayoutProvider>
export default Vue.extend({
  name: "PageLayout",

  provide() {
    return {
      internalReviewsFilter: this.internalReviewsFilter
    }
  },

  props: {
    page: {
      type: Object as () => Page,
      required: true
    },
    noSeo: {
      type: Boolean
    }
  },

  data(): ComponentData {
    return {
      layout: "",
      internalReviewsFilter: {}
    }
  },

  async fetch() {
    await this.setBreadcrumbs({
      breadcrumbs: this.page!.seo!.breadcrumbs
    })
  },

  head(): MetaInfo {
    const head: MetaInfo = {}

    const link = []

    if (this.page && this.page.seo) {
      const seo = this.page.seo
      const frontendUrl = this.page.frontendUrl

      const facebookTags = [
        ...(seo.opengraphType
          ? [
              {
                property: "og:type",
                content: seo.opengraphType
              }
            ]
          : []),
        ...(seo.opengraphTitle
          ? [
              {
                property: "og:title",
                content: seo.opengraphTitle
              }
            ]
          : []),
        ...(seo.opengraphDescription
          ? [
              {
                property: "og:description",
                content: seo.opengraphDescription
              }
            ]
          : []),
        ...(frontendUrl
          ? [
              {
                property: "og:url",
                content: frontendUrl
              }
            ]
          : []),
        ...(seo.opengraphImage &&
        seo.opengraphImage.mediaDetails &&
        seo.opengraphImage.mediaDetails.sizes &&
        seo.opengraphImage.mediaDetails.sizes.length > 0
          ? [
              {
                property: "og:image",
                content: this.replaceUrlS3(
                  seo.opengraphImage.mediaDetails.sizes.find(s => s.name === "thumbnail")
                    ?.sourceUrl,
                  this.$config
                )
              }
            ]
          : [])
      ]

      const twitterTags = [
        ...(seo.twitterTitle
          ? [
              {
                property: "twitter:title",
                content: seo.twitterTitle
              }
            ]
          : []),
        ...(seo.twitterDescription
          ? [
              {
                property: "twitter:description",
                content: seo.twitterDescription
              }
            ]
          : []),
        ...(seo.twitterImage &&
        seo.twitterImage.mediaDetails &&
        seo.twitterImage.mediaDetails.sizes &&
        seo.twitterImage.mediaDetails.sizes.length > 0
          ? [
              {
                property: "twitter:image",
                content: this.replaceUrlS3(
                  (seo.twitterImage.mediaDetails.sizes.find(s => s.name === "thumbnail") || {})
                    .sourceUrl,
                  this.$config
                )
              }
            ]
          : [])
      ]

      head.meta = [
        {
          name: "robots",
          content:
            this.$config.appName === ""
              ? "noindex, nofollow"
              : seo.metaRobotsNoindex + ", " + seo.metaRobotsNofollow
        },
        {
          hid: "description",
          name: "description",
          content: seo ? seo.metaDesc : ""
        },
        ...facebookTags,
        ...twitterTags
      ]
      if (!this.noSeo) {
        head.script = [
          {
            type: "application/ld+json",
            json: buildStructuredData(this.page)
          }
        ] as any
      }
      head.title = this.page.title
    }

    if (
      [
        "PageLayoutAdvice",
        "PageLayoutBlog",
        "PageLayoutDefault",
        "PageLayoutRightSidebar"
      ].includes(this.layout) &&
      this.page &&
      this.page.featuredImage &&
      this.page.featuredImage.node
    ) {
      link.push({
        // @ts-ignore: in Array
        rel: "preload",
        // @ts-ignore: in Array
        as: "image",
        // @ts-ignore: in Array
        href: this.replaceUrlS3(this.page.featuredImage.node.sourceUrl, this.$config)
      })
    }

    const canonical = this.page!.seo!.canonical
    const noindex = this.page!.seo!.metaRobotsNoindex === "noindex"
    const seoPage = !noindex

    if (seoPage && canonical && this.$config.frontendHost) {
      link.push({
        // @ts-ignore: in Array
        rel: "canonical",
        // @ts-ignore: in Array
        href: canonical.includes(this.$config.cmsHost)
          ? canonical.replace(this.$config.cmsHost, this.$config.frontendHost).replace(/\/$/, "")
          : canonical.replace(/\/$/, "")
      })
    }

    head.link = [...link]

    return head
  },

  computed: {
    ...mapGetters("auth", {
      nonce: GET_FW_TOKEN
    })
  },

  created() {
    const hasLatLongs =
      this.page &&
      this.page.location &&
      this.page.location.mapPinsLatitude &&
      this.page.location.mapPinsLongitude

    if (hasLatLongs) {
      this.setMapLocations(this.page.location)
      this.setLocationInfo(this.page.location)
    }

    if (this.page && this.page.pageInternalReviews) {
      this.setInternalReviews(this.page.pageInternalReviews)
    }

    this.layout = this.getLayoutFromTemplate()
    this.internalReviewsFilter = this.page.internalReviews
  },

  async mounted() {
    const query = this.$route.query
    query.utm_media =
      query.utm_media || (this.page!.seo!.metaRobotsNoindex === "index" ? "seo" : "ppc")

    await this.authenticateAndUpdatePhone()
    await this.sendTrackingEvents()
    await this.useCreditCode()
  },

  methods: {
    replaceUrlS3,

    ...mapActions("pages", {
      setBreadcrumbs: SET_BREADCRUMBS
    }),
    ...mapActions("location", {
      setMapLocations: SET_MAP_LOCATIONS,
      setLocationInfo: SET_LOCATION_INFO
    }),
    ...mapActions("internal-reviews", {
      setInternalReviews: SET_INTERNAL_REVIEWS
    }),
    ...mapActions("auth", {
      setFwToken: SET_FW_TOKEN,
      setUser: SET_USER
    }),
    ...mapActions("phone-number", {
      setPhoneNumberValue: SET_PHONE_NUMBER_VALUE
    }),
    ...mapActions("credit-code", {
      setCreditCodeValue: SET_CREDIT_CODE_VALUE
    }),

    getLayoutFromTemplate(): string {
      let template =
        this.page &&
        this.page.template &&
        this.page.template.templateName
          .trim()
          .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))

      if (
        ![
          "Advice",
          "Article",
          "Avb",
          "Blank",
          "Blog",
          "Default",
          "RemovalsHub",
          "RightSidebar",
          "StaticDocument",
          "Rebrand"
        ].includes(template)
      ) {
        template = "Default"
      }

      return "PageLayout" + template
    },

    async authenticateAndUpdatePhone(): Promise<void> {
      const auth = new AuthenticationService(this.$axios, this.$config)
      const profile = await auth.getProfile()
      if (profile && profile.fw_token) {
        await this.setFwToken(profile.fw_token)
        await this.updatePhoneNumber()
      }
      if (profile && profile.user) {
        await this.setUser(profile.user)
      }
    },

    async updatePhoneNumber(): Promise<void> {
      const phoneService = new PhoneNumberService(this.$axios, this.$config)

      if (this.page && this.$route && this.nonce) {
        const phoneNumber: PhoneNumberPair = await phoneService.updatePhoneNumber(
          this.page,
          this.$route,
          this.nonce
        )
        await this.setPhoneNumberValue(phoneNumber)

        const trackingData: CallUsPayload = {
          phoneNumber: phoneNumber.base,
          label: "lego_phone_number",
          action: "viewed"
        }
        if (phoneNumber.extension !== "") {
          // @ts-ignore
          trackingData.extension = phoneNumber.extension
        }
        this.$snowplow.trackCallUs(trackingData)
      }
    },

    async useCreditCode(): Promise<void> {
      const creditCodeService = new CreditCodeService(this.$axios, this.$config)
      const creditCodeResponse = await creditCodeService.getCreditCodeValue(this.page, this.$route)
      if (creditCodeResponse && creditCodeResponse.value) {
        this.setCreditCodeValue(parseInt(creditCodeResponse.value))
      }
    },

    async sendTrackingEvents(): Promise<void> {
      const trackingService = new TrackingService(this.$axios, this.$config)
      const response = await trackingService.postTrackingEvent(
        { label: "LEGO - " + this.$route.path },
        this.$route,
        this.$cookies,
        this.page,
        window
      )

      if (response && response.data && response.data.track_id) {
        this.$snowplow.trackAnyVanTrackId(response.data.track_id)
      }
    }
  }
})
